
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import store from "@/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "CZPerformanceSummary",

  components: {},
  setup() {
    const chartOptions = {
      chart: {
        type: "line",
        height: 350,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 5,
        show: true,
      },
      markers: {
        size: 8,
      },

      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
          formatter: function (val, timestamp) {
            return moment(new Date(timestamp)).format("MMM DD");
          },
          style: {
            colors: "#9899ac",
          },
        },
      },
      yaxis: {
        title: {
          text: "£/MWh",
          style: {
            fontSize: "15px",
          },
        },
      },
      legend: {
        show: true,
        floating: false,
        fontSize: "20px",
      },
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Performance Summary", [
        "Flexible Procurement",
      ]);
      getPerformanceSummary();
    });
    const loading = ref<boolean>(false);

    /**
     *@description For auto refreshing the page on selection change
     */
    watch(
      () => [
        store.getters.layoutConfig("flexibleProcurement.scenarioName"),
        store.getters.layoutConfig("flexibleProcurement.reportName"),
        store.getters.layoutConfig("flexibleProcurement.current"),
      ],
      function () {
        getPerformanceSummary();
      }
    );
    const series = reactive([{}]);
    series.splice(0);

    /**
     *@description For getting performance summary report
     */
    function getPerformanceSummary() {
      loading.value = true;

      let AccountId = store.getters.AccountId;
      let current = store.getters.layoutConfig("flexibleProcurement.current");
      let FPreportName = store.getters.layoutConfig(
        "flexibleProcurement.reportName"
      );
      let FPscenarioName = store.getters.layoutConfig(
        "flexibleProcurement.scenarioName"
      );

     const accountInfo: any = {
        clientId: AccountId,
        productId: 4,
        reportId: FPreportName,
        scenarioId: FPscenarioName,
        iscurrent: current,
      };
      ApiService.post("api/FlexProcurement/getFlexProcrement", accountInfo) 
             .then(async ({ data }) => {
          const response = await data.dayCostList;
          series.splice(0);
          //Making the series with dynamic values
          series.push({
            name: "MTM",
            data: response.map((a) => [new Date(a.date), a.mtm]),
          });
          series.push({
            name: "Market Price",
            data: response.map((a) => [new Date(a.date), a.market]),
          });
          series.push({
            name: "Budget",
            data: response.map((a) => [new Date(a.date), a.baseline]),
          });
          loading.value = false;
        })
        .catch();
    }
    return {
      series,
      chartOptions,
      loading,
    };
  },
});
